<template>
    <div>
        <div class="my-Card">
            <div class="my-header">
                <img v-if="customer.imgUrl == null" src="../../../statics/images/my.png" @click="$router.push({ name: 'sczp' })">
                <img v-else :src="customer.imgUrl" @click="$router.push({ name: 'sczp' })">
            </div>
            <div class="my-username">{{customer.fullName}}</div>
            <van-button @click="bindWechat" round size="mini" type="primary" style="position: absolute;right: 2%">{{ customer.weixinOpenId == null || customer.weixinOpenId == '' ? '绑定微信' : '解绑微信' }}</van-button>
        </div>
        <van-cell-group style="margin-top: 20px;">
            <van-cell icon="user-o" title="编辑志愿者信息" is-link  @click="$router.push({ name: 'vzlxg' })"/>
            <van-cell icon="todo-list-o" title="活动记录" is-link  @click="$router.push({ name: 'vhdgl' })"/>
            <van-cell icon="lock" title="修改密码" is-link  @click="$router.push({ name: 'xgmm' })"/>
            <van-cell icon="fire-o" title="选择主题" is-link  @click="showThemePopup=true"/>
        </van-cell-group>
        <div class="btns"><van-button block type="danger" @click="exit">退出登录</van-button></div>
        <!-- 选择主题 -->
        <van-popup v-model="showThemePopup" position="bottom" style="height: 100px;display: flex;align-items: center">
            <van-radio-group v-model="curTheme" style="margin-left: 20px;" @change="selectTheme">
                <van-radio name="white">白色</van-radio>
                <van-radio name="dodgerblue" style="margin-top: 15px;">蓝色</van-radio>
            </van-radio-group>
        </van-popup>
        <!-- 选择主题 -->
    </div>
</template>
<script>
    import {Cell, CellGroup, Button, Popup, RadioGroup, Radio } from 'vant';
    import Tools from "@/api/Tools";
    import CustomerApi from "../../api/CustomerApi";
    export default {
        name: 'mainMy',
        components: {
            VanCell: Cell,
            VanCellGroup: CellGroup,
            VanButton: Button,
            VanPopup: Popup,
            VanRadioGroup: RadioGroup,
            VanRadio: Radio
        },
        data() {
          return {
              customer: Tools.getCurCust(),
              showThemePopup: false,
              curTheme: this.$store.state.theme,
              // 通过 actions 属性来定义菜单选项
              actions: [{ text: '选项一' }, { text: '选项二' }, { text: '选项三' }],
              loading: false
          }
        },
        methods: {
            exit() {
                var openId = Tools.getSession('openId')
                this.$store.dispatch('clear');
                sessionStorage.clear();
                Tools.addSession('openId', openId) // 保留openId
                this.$router.replace({ name: 'vlogin' })
            },
            selectTheme(name) {
                this.showThemePopup=false
                if (this.curTheme=='white') {
                    this.$store.commit('changeTheme', name)
                } else {
                    this.$store.commit('changeTheme', name)
                }
                this.curTheme = name
            },
            bindWechat() {
                var openId = null
                if (this.customer.weixinOpenId == null) {
                    openId = Tools.getSession('openId')
                } else {
                    openId = null
                }
                this.loading=true

                CustomerApi.bindOfficalOpenId({ custId: this.customer.custId, weixinOpenId: openId }).then(response => {
                    this.loading=false
                    if (response.code==100){
                        this.$set(this.customer, 'weixinOpenId', openId)
                        Tools.setCurCust(this.customer)
                        if (openId == null) {
                          this.$dialog.alert({ message:'解绑成功。'})
                        } else {
                          this.$dialog.alert({ message:'绑定成功。'})
                        }

                    } else {
                        this.$toast('绑定失败，错误提示：' + response.msg)
                    }
                }).catch(()=>{this.loading=false})
            }
        }
    }
</script>
<style scoped>
    .my-Card {
        width: 100%;
        height: 100px;
        background-image: linear-gradient(to right, dodgerblue, lightblue);
        display: flex;
        position: relative;
        align-items: center;
    }

    .my-header {
        width: 50px;
        height: 50px;
        background-color: white;
        border-radius: 50%;
        position: relative;
        border: 2px solid white;
        margin-left: 5%;
        overflow: hidden;
    }

    .my-header img {
        width: 100%;
        border-radius: 50%;
    }

    .my-username {
        margin-left: 15px;
        color: white;
        font-weight: bold;
    }
    .btns {
        width: 90%;
        margin: 20px auto;
    }
</style>
