<template>
  <div>
    <div class="swipe-frame">
      <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
        <van-swipe-item v-for="(banner, index) in banners" :key="index" @click="toActivity(banner)">
          <img class="swipe-img" :src="banner.imgUrl">
        </van-swipe-item>
      </van-swipe>
    </div>
    <van-notice-bar left-icon="volume-o" text="欢迎使用粤美·巾帼故事妈妈志愿者平台。"/>
    <div class="edu-group" style="margin: 20px auto;padding: 0px;overflow: hidden;">
      <van-grid :column-num="3" >

        <van-grid-item @click="$router.push({ name: 'cjhdlist' })" v-if="customer.lgs_CardType1 == 50">
          <img class="grid-item-img" src="../../../statics/images/vcjhd.png">
          <div class="grid-item-tips">活动报名</div>
        </van-grid-item>
        <van-grid-item @click="$router.push({ name: 'vhdgl' })" v-if="customer.lgs_CardType1 == 50">
          <img class="grid-item-img" src="../../../statics/images/vhdjl.png">
          <div class="grid-item-tips">活动记录</div>
        </van-grid-item>
        <van-grid-item @click="$router.push({ name: 'vhdxd' })" v-if="customer.lgs_CardType1 == 50">
          <img class="grid-item-img" src="../../../statics/images/vhdxd.png">
          <div class="grid-item-tips">活动反馈</div>
        </van-grid-item>
        <van-grid-item @click="$router.push({ name: 'vxxtdlist' })"  v-if="customer.lgs_CardType1 == 50">
          <img class="grid-item-img" src="../../../statics/images/vxxtd.png">
          <div class="grid-item-tips">公益课程</div>
        </van-grid-item>
        <van-grid-item @click="$router.push({ name: 'vpyqlist' })"  v-if="customer.lgs_CardType1 == 50">
          <img class="grid-item-img" src="../../../statics/images/vpyq.png">
          <div class="grid-item-tips">朋友圈</div>
        </van-grid-item>
<!--        <van-grid-item @click="$router.push({ name: 'xgmm' })"  v-if="customer.lgs_CardType1 == 50">
          <img class="grid-item-img" src="../../../statics/images/xgmm.png">
          <div class="grid-item-tips">修改密码</div>
        </van-grid-item>
        <van-grid-item @click="bindWechat()">
          <img class="grid-item-img" src="../../../statics/images/bdwx.png">
          <div class="grid-item-tips">{{ customer.weixinOpenId == null || customer.weixinOpenId == '' ? '绑定微信' : '解绑微信' }}</div>
        </van-grid-item>-->

      </van-grid>
    </div>

  </div>
</template>
<script>
import { Swipe, SwipeItem, Grid, GridItem, NoticeBar } from 'vant';
import CustomerApi from "../../api/CustomerApi";
import Tools from "../../api/Tools";
import ActivityApi from "@/api/ActivityApi";
export default {
  name: 'mainIndex',
  components: {
    VanSwipe: Swipe,
    VanSwipeItem: SwipeItem,
    VanNoticeBar: NoticeBar,
    VanGrid: Grid,
    VanGridItem: GridItem
  },
  data() {
    return {
      customer: Tools.getCurCust(),
      identityType: Tools.getIdentityType(),
      banners: []
    }
  },
  methods: {
    bindWechat() {
      var openId = null
      if (this.customer.weixinOpenId == null) {
        openId = Tools.getSession('openId')
      } else {
        openId = null
      }
      // this.loading=true

      CustomerApi.bindOfficalOpenId({ custId: this.customer.custId, weixinOpenId: openId }).then(response => {
        // this.loading=false
        if (response.code==100){
          this.$set(this.customer, 'weixinOpenId', openId)
          Tools.setCurCust(this.customer)

          if (openId == null) {
            this.$toast('解绑成功。')
          } else {
            this.$toast('绑定成功。')
          }

        } else {
          this.$toast('绑定失败，错误提示：' + response.msg)
        }
      }).catch(()=>{
        // this.loading=false
      })
    },
    getActivityBanners() {
      ActivityApi.getActivityBanners({ isVolunteer: 1 }).then(response => {
        this.banners = response.res
      })
    },
    toActivity(banner) {
      if (banner.href == null || banner.href == '') {
        return
      }
      location.href = '#/' + banner.href
    }
  },
  mounted() {
    this.getActivityBanners()
  }
}
</script>
<style scoped>
.my-swipe .van-swipe-item {
  color: #fff;
  font-size: 20px;
  text-align: center;
}
.swipe-img {
  width: 100%;
  height: 100%;
}
.swipe-frame {
  width: 100%;
  position: relative;
}
.grid-item-img {
  width: 30px;
  height: auto;
}
.grid-item-tips {
  margin-top: 10px;
  font-size: 14px;
}
</style>
